import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { de } from 'primelocale/de.json';

import { fetchAuthSession } from 'aws-amplify/auth';
import { createApp } from 'vue';

import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';

import '@/plugins/auth';
import './assets/main.css';

import { intellivendPreset } from './preset';

// ToDo: remove this if everything is piped via backend
import { OpenAPI } from '@/services/generated';

OpenAPI.BASE = import.meta.env.VITE_CORE_ENDPOINT;
OpenAPI.WITH_CREDENTIALS = true;

OpenAPI.TOKEN = async () => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {};

  if (!accessToken) {
    throw new Error('Missing access token');
  }

  return accessToken.toString();
};

const app = createApp(App);

const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  trackComponents: true, // alternatively, specify components by name like this: [ "App", "MyComponent" ], or turn it off completely with false
  // Tracing
  tracesSampleRate: SENTRY_ENVIRONMENT === 'prod' ? 0.1 : 0.5,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['intellivend.app'],
  // Session Replay
  replaysSessionSampleRate: SENTRY_ENVIRONMENT === 'prod' ? 0.1 : 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(PrimeVue, {
  locale: de,
  theme: {
    preset: intellivendPreset,
    options: {
      prefix: 'p',
      cssLayer: false,
      darkModeSelector: '.dark',
    },
  },
});
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);

app.directive('tooltip', Tooltip);

app.mount('#app');
