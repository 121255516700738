<script setup lang="ts">
import { useBreadcrumbs } from '@/composables/breadcrumbs';

const breadcrumbs = useBreadcrumbs();
</script>

<template>
  <p-breadcrumb :model="breadcrumbs">
    <template #item="{ item, props }">
      <router-link v-bind="props.action" :to="{ name: item.to }">
        <fa-icon v-if="item.homeIcon" class="text-primary-800" :icon="item.homeIcon" />
        <span v-else class="p-menuitem-text">{{ item.label }}</span>
      </router-link>
    </template>
  </p-breadcrumb>
</template>

<style scoped>
.p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
  @apply text-primary-500;
}
</style>
